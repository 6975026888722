body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
 */  
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */

    
}

*{
  font-family: 'Open Sans', sans-serif;
   margin: 0;
  padding: 0;
}


.navbar-div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    height: 12vh;
}
.navbar-div-logo{
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
   text-align: center;
}
.navbar-div-text{
    -webkit-flex-basis: 70%;
            flex-basis: 70%;
    color: #308783
}

.subnavmargin{
    
}
.logo-img{
    width: 30%
}
.navitems{
    color:white;
}

/* Small nav Div */


 .navbar-div-small{
    background-color: #007BFF;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    height: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
} 
.caret-right{
    outline: none;
}

.caret-right::after{
    content: "";
    float: right;
    margin-top: .5em;
}

.right-drop{
    -webkit-transform: translate3d(110px, -8px, 0px) !important;
            transform: translate3d(110px, -8px, 0px) !important;
}

@media only screen and (max-width: 600px){
    .navbar-div{
        
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .logo-img{
        width: 60px

    }

    .navbar-div-text h5{
        font-size: 0.8em
    }

    .navbar-div-text{
        font-size: 0.9em ;
        width:100vw;
    }
}
.col-8,
.col-4 {
padding: 1rem;
text-align: left;
max-width: 100%;
}

.styled-title{
    padding-bottom: 10px;
}

.styled-title>h3>span{
    color:brown;
    padding-right: 8px
}

.styled-title>div{
    height: 5px;
    width:60px;
    background-color: brown;
    
}

.para{
    text-align: justify;
    padding-top: 15px
}
.card-date-news{
    background-color: #1f1f1f;
    color: white !important;
    height: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.notification-div{
    background-color: #f2f2f2;
    padding: 10px;
    border-left: solid 5px #F54456;
}

