.navbar-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 12vh;
}
.navbar-div-logo{
    flex-basis: 100%;
   text-align: center;
}
.navbar-div-text{
    flex-basis: 70%;
    color: #308783
}

.subnavmargin{
    
}
.logo-img{
    width: 30%
}
.navitems{
    color:white;
}

/* Small nav Div */


 .navbar-div-small{
    background-color: #007BFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;
} 
.caret-right{
    outline: none;
}

.caret-right::after{
    content: "";
    float: right;
    margin-top: .5em;
}

.right-drop{
    transform: translate3d(110px, -8px, 0px) !important;
}

@media only screen and (max-width: 600px){
    .navbar-div{
        
        padding-left: 0vw;
        padding-right: 0vw;
    }

    .logo-img{
        width: 60px

    }

    .navbar-div-text h5{
        font-size: 0.8em
    }

    .navbar-div-text{
        font-size: 0.9em ;
        width:100vw;
    }
}