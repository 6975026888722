.col-8,
.col-4 {
padding: 1rem;
text-align: left;
max-width: 100%;
}

.styled-title{
    padding-bottom: 10px;
}

.styled-title>h3>span{
    color:brown;
    padding-right: 8px
}

.styled-title>div{
    height: 5px;
    width:60px;
    background-color: brown;
    
}

.para{
    text-align: justify;
    padding-top: 15px
}